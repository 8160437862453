import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/plumbing/Head'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" description="This route was not found" />
    <section className="ten-central" style={{ display: 'block', marginTop: '8rem' }}>
      <div>
        <h1>404: Not Found</h1>
        <p>rip</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
